var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(_vm.productDetails.linkId)?_c('b-button-group',{staticClass:"mb-1"},[_c('b-button',{attrs:{"variant":!+_vm.lang ? 'primary' : 'outline-primary',"to":'/main/products/details/' +
          (_vm.productDetails.language == 1 ? _vm.id : _vm.productDetails.linkId) +
          '/0'}},[_vm._v("عربي")]),_c('b-button',{attrs:{"to":'/main/products/details/' +
          (_vm.productDetails.language == 2 ? _vm.id : _vm.productDetails.linkId) +
          '/1',"variant":!+_vm.lang ? 'outline-primary' : 'primary'}},[_vm._v("English")])],1):_vm._e()],1),_c('ValidationObserver',{ref:"observer"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-card',{staticClass:"mb-2",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"align-items-center"},[_c('h4',{staticClass:"mr-auto mb-0"},[_vm._v("تفاصيل المنتج")])]),_c('b-card-body',[_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex justify-content-between py-1"},[_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{model:{value:(_vm.productDetails.isHidden),callback:function ($$v) {_vm.$set(_vm.productDetails, "isHidden", $$v)},expression:"productDetails.isHidden"}}),_c('label',{staticClass:"ml-50"},[_vm._v("مخفي")])],1),_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{model:{value:(_vm.productDetails.certified),callback:function ($$v) {_vm.$set(_vm.productDetails, "certified", $$v)},expression:"productDetails.certified"}}),_c('label',{staticClass:"ml-50"},[_vm._v("معتمد")])],1)]),_c('BTextInputWithValidation',{attrs:{"rules":"required","name":"اسم المنتج","label":"اسم المنتج","placeholder":"ادخل اسم المنتج"},model:{value:(_vm.productDetails.name),callback:function ($$v) {_vm.$set(_vm.productDetails, "name", $$v)},expression:"productDetails.name"}}),_c('b-form-group',{attrs:{"label":"التصنيفات"}},[_c('validation-provider',{attrs:{"name":"التصنيف","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"clearable":false,"label":"name","options":_vm.productCategoriesList,"reduce":function (option) { return option.id; }},model:{value:(_vm.productDetails.categoryBreakId),callback:function ($$v) {_vm.$set(_vm.productDetails, "categoryBreakId", $$v)},expression:"productDetails.categoryBreakId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('BTextInputWithValidation',{attrs:{"rules":"required|positive","name":"سعر المنتج SP","label":"سعر المنتج SP","type":"number","placeholder":"أدخل السعر"},model:{value:(_vm.productDetails.sypPrice),callback:function ($$v) {_vm.$set(_vm.productDetails, "sypPrice", $$v)},expression:"productDetails.sypPrice"}}),_c('BTextInputWithValidation',{attrs:{"rules":"required|positive|regex:[0-9.]","name":"سعر المنتج $","label":"سعر المنتج $","placeholder":"أدخل السعر"},model:{value:(_vm.productDetails.usdPrice),callback:function ($$v) {_vm.$set(_vm.productDetails, "usdPrice", $$v)},expression:"productDetails.usdPrice"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("صورة المنتج")]),_c('fileDragDrop',{attrs:{"id":"employee-photo","height":"100px","type":"image","title":"صورة جديدة"},on:{"uploaded":_vm.uploadThumbImage}}),_c('img',{staticClass:"w-100",staticStyle:{"max-height":"320px","object-fit":"cover"},attrs:{"src":!_vm.productDetails.imageDto.base64
                      ? _vm.productDetails.imageDto.path.replace(
                          '~',
                          _vm.$store.getters['app/domainHost']
                        )
                      : _vm.productDetails.imageDto.base64}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(
                    !_vm.productDetails.imageDto.base64 && !_vm.productDetails.imageDto.path
                  ),expression:"\n                    !productDetails.imageDto.base64 && !productDetails.imageDto.path\n                  "}],staticClass:"text-danger"},[_vm._v(" صورة المنتج مطلوبة ")])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"editor-container"},[_c('quill-editor',{ref:"myTextEditor",staticClass:"editor",attrs:{"value":_vm.productDetails.description,"options":_vm.editorOption},on:{"change":_vm.onEditorChange}})],1)])],1)],1)],1),_c('b-card-footer',[_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-1",staticStyle:{"max-width":"100px"},attrs:{"variant":"primary","type":"submit"}},[_vm._v("تعديل")]),_c('b-button',{staticStyle:{"max-width":"100px"},attrs:{"variant":"outline-primary","to":"../"}},[_vm._v("تراجع")])],1)]),_c('b-col',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('b-button',{staticStyle:{"max-width":"100px"},attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.deleteItemProduct(_vm.id)}}},[_vm._v("حذف")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }