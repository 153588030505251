<template>
  <div>
    <div>
      <b-button-group v-if="productDetails.linkId" class="mb-1">
        <b-button
          :variant="!+lang ? 'primary' : 'outline-primary'"
          :to="
            '/main/products/details/' +
            (productDetails.language == 1 ? id : productDetails.linkId) +
            '/0'
          "
          >عربي</b-button
        >
        <b-button
          :to="
            '/main/products/details/' +
            (productDetails.language == 2 ? id : productDetails.linkId) +
            '/1'
          "
          :variant="!+lang ? 'outline-primary' : 'primary'"
          >English</b-button
        >
      </b-button-group>
    </div>
    <ValidationObserver ref="observer">
      <b-form @submit.prevent="onSubmit">
        <b-card no-body class="mb-2">
          <b-card-header class="align-items-center">
            <h4 class="mr-auto mb-0">تفاصيل المنتج</h4>
          </b-card-header>
          <b-card-body>
            <b-card-text>
              <b-row>
                <b-col cols="12" md="6">
                  <div class="d-flex justify-content-between py-1">
                    <div class="d-flex">
                      <b-form-checkbox
                        v-model="productDetails.isHidden"
                      ></b-form-checkbox>
                      <label class="ml-50">مخفي</label>
                    </div>
                    <div class="d-flex">
                      <b-form-checkbox
                        v-model="productDetails.certified"
                      ></b-form-checkbox>
                      <label class="ml-50">معتمد</label>
                    </div>
                  </div>
                  <BTextInputWithValidation
                    rules="required"
                    name="اسم المنتج"
                    label="اسم المنتج"
                    placeholder="ادخل اسم المنتج"
                    v-model="productDetails.name"
                  />
                  <b-form-group label="التصنيفات">
                    <validation-provider
                      #default="{ errors }"
                      name="التصنيف"
                      rules="required"
                    >
                      <v-select
                        v-model="productDetails.categoryBreakId"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        :clearable="false"
                        label="name"
                        :options="productCategoriesList"
                        :reduce="(option) => option.id"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <BTextInputWithValidation
                    rules="required|positive"
                    name="سعر المنتج SP"
                    label="سعر المنتج SP"
                    type="number"
                    placeholder="أدخل السعر"
                     v-model="productDetails.sypPrice"
                  />
                  <BTextInputWithValidation
                    rules="required|positive|regex:[0-9.]"
                    name="سعر المنتج $"
                    label="سعر المنتج $"
                    placeholder="أدخل السعر"
                     v-model="productDetails.usdPrice"
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <label>صورة المنتج</label>
                  <fileDragDrop
                    @uploaded="uploadThumbImage"
                    id="employee-photo"
                    height="100px"
                    type="image"
                    title="صورة جديدة"
                  />
                  <img
                    :src="
                      !productDetails.imageDto.base64
                        ? productDetails.imageDto.path.replace(
                            '~',
                            $store.getters['app/domainHost']
                          )
                        : productDetails.imageDto.base64
                    "
                    style="max-height: 320px; object-fit: cover"
                    class="w-100"
                  />
                  <small
                    class="text-danger"
                    v-show="
                      !productDetails.imageDto.base64 && !productDetails.imageDto.path
                    "
                  >
                    صورة المنتج مطلوبة
                  </small>
                </b-col>
                <b-col cols="12">
                  <div class="editor-container">
                    <quill-editor
                      class="editor"
                      ref="myTextEditor"
                      :value="productDetails.description"
                      :options="editorOption"
                      @change="onEditorChange"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
          <b-card-footer>
            <b-row>
              <b-col>
                <div class="d-flex">
                  <b-button
                    class="mr-1"
                    variant="primary"
                    style="max-width: 100px"
                    type="submit"
                    >تعديل</b-button
                  >
                  <b-button variant="outline-primary" style="max-width: 100px" to="../"
                    >تراجع</b-button
                  >
                </div>
              </b-col>
              <b-col style="display: flex; justify-content: flex-end">
                <b-button
                  @click="deleteItemProduct(id)"
                  style="max-width: 100px"
                  variant="outline-primary"
                  >حذف</b-button
                >
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import hljs from "highlight.js";
import debounce from "lodash/debounce";
export default {
  props: {
    lang: String,
    id: String,
  },
  components: {
    fileDragDrop,
    ValidationObserver,
    ValidationProvider,
    BTextInputWithValidation,
    vSelect,
    quillEditor,
  },
  computed: {
    ...mapGetters(["productDetails", "productCategoriesList"]),
    editor() {
      return this.$refs.myTextEditor.quill;
    },
  },
  async created() {
    await this.getData({ type: 1, lang: !+this.lang ? 1 : 2 });
    this.getProductById({ type: 3, id: this.id });
  },
  data: () => ({
    editorOption: {
      modules: {
         toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ 'header': 1 }, { 'header': 2 }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  [{ 'script': 'sub' }, { 'script': 'super' }],
                  [{ 'indent': '-1' }, { 'indent': '+1' }],
                  [{ 'direction': 'rtl' }],
                  [{ 'size': ['small', false, 'large', 'huge'] }],
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                  [{ 'font': [] }],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'align': [] }],
                  ['clean'],
                  ['link', 'image', 'video']
              ],
        syntax: {
          highlight: (text) => hljs.highlightAuto(text).value,
        },
      },
      placeholder: "ادخل شرح عن المنتجات",
    },
  }),
  methods: {
    ...mapActions(["deleteItemProduct", "getData", "getProductById", "updateProduct"]),
    onSubmit() {
      this.$refs.observer.validate().then((success) => {
        if (
          (success && this.productDetails.imageDto.base64) ||
          this.productDetails.imageDto.path
        ) {
          this.updateProduct({
            id: this.productDetails.id,
            name: this.productDetails.name,
            description: this.productDetails.description,
            sypPrice: +this.productDetails.sypPrice,
            usdPrice: +this.productDetails.usdPrice,
            isHidden: this.productDetails.isHidden,
            certified: this.productDetails.certified,
            imageDto: this.productDetails.imageDto,
            linkId: this.productDetails.linkId,
            language: this.productDetails.language,
            categoryBreakId: this.productDetails.categoryBreakId,
            mainCategory: this.productDetails.mainCategory,
            subCategory: this.productDetails.subCategory,
          });
        }
      });
    },
    onEditorChange: debounce(function (value) {
      this.productDetails.description = value.html;
    }, 466),
    uploadThumbImage(base) {
      this.productDetails.imageDto.base64 = base[0].base64;
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-container {
  height: 270px;
  .editor {
    height: 200px;
  }
}
</style>
